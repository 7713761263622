import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import SiteMetadata from "../components/site-metadata"

export default function HomePage() {
  const data = useStaticQuery(graphql`
    {
      photo: file(name: { eq: "profile" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <SiteMetadata />
      <div className="main-wrapper">
        <header className="site-header">
          <p className="name">Rhett Pringle</p>
          <a href="mailto:rhett@riskliving.com" className="contact-button">
            Contact
          </a>
        </header>
        <section className="hero">
          <div className="left">
            <div>
              <h1 className="hero-text">
                Videographer
                <br />
                &amp; Digital Creative
              </h1>
              <p>I'm a creative professional with experience as a collaborative team leader and individual performer. I bring an opportunity-focused mentality and a contagious enthusiasm for new ideas, experiences and solutions.</p>
            </div>
            <p>Available for hire</p>
          </div>
          <div className="right">
            <Img
              fluid={data.photo.childImageSharp.fluid}
              objectPosition="50% 15%"
            />
          </div>
        </section>
        <footer className="site-footer">
          <p>
            <small>
              &copy; {new Date().getFullYear()} Rhett Pringle - All rights
              reserved.
            </small>
          </p>
        </footer>
      </div>
    </>
  )
}
