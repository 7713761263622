import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"

import cover from "../assets/images/cover.png"

const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
    }
  `)
  return site.siteMetadata
}

export default function SiteMetadata() {
  const { siteUrl, title, description } = useSiteMetadata()
  const { pathname } = useLocation()

  const href = `${siteUrl}${pathname}`
  const locale = "en"

  return (
    <Helmet defer={false} defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <html lang={locale} />
      <link rel="canonical" href={href} />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />

      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={`${siteUrl}${cover}`} />
      <meta
        property="og:image:alt"
        content="Rhett Pringle: Videographer and Digital Creative"
      />
      <meta property="og:description" content={description} />

      <meta name="twitter:card" content="summarysummary_large_image" />
    </Helmet>
  )
}
